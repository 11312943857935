<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main v-if="dataLoaded">
      <trac-validation-observer slim v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(sendForm)" class="md:ml-12 mr-4 md:mr-0 mb-6">
          <trac-back-button>{{
            fromCategoryModule ? "Create Category" : "Products"
          }}</trac-back-button>
          <div class="flex-col mt-12 mb-8">
            <h2>Products</h2>
            <h3 class="font-bold mt-4 text-2xl">New Products</h3>
          </div>
          <div class="bg-white px-8 py-8 rounded-lg big-shadow">
            <trac-custom-header>
              <template slot="leading">Product </template>
              <template slot="trailing">Details</template>
            </trac-custom-header>
            <div class="grid md:flex md:flex-wrap pb-6">
              <div
                class="md:w-2/12 h-48 relative"
                v-if="product.image || imageToUpload.src"
              >
                <img
                  class="h-40 w-full block"
                  :src="imageToUpload.src || product.image"
                  alt=""
                />
                <input
                  class="absolute bottom-0"
                  accept="image/png, image/jpeg, image/jpg"
                  @change="selectImage"
                  type="file"
                  name=""
                  id="product-image"
                  v-show="false"
                />
                <button
                  @click="removeImage"
                  class="w-full p-2 text-sm bg-primaryGray text-white"
                >
                  Remove Image
                </button>
              </div>
              <div class="flex-wrap flex-column md:w-2/12" v-else>
                <div class="flex w-full w-48 h-40 no-img-bg relative">
                  <input
                    class="absolute bottom-0"
                    accept="image/png, image/jpeg, image/jpg"
                    @change="selectImage"
                    type="file"
                    name=""
                    id="product-image"
                    v-show="false"
                  />
                  <div
                    @click="triggerFileOpen"
                    class="flex w-full md:w-48 cursor-pointer h-10 bg-primaryGray mt-auto text-white justify-center items-center font-medium text-sm"
                  >
                    Upload Image
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap md:ml-10 w-full md:w-6/12 justify-between mt-10 md:mt-0">
                <div class="w-full flex-column md:w-5/12">
                  <trac-validation-provider
                    name="product name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <trac-input
                      placeholder="Product Name"
                      v-model="product.title"
                    ></trac-input>
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>
                </div>
                <div class="md:mr-24 w-full">
                  <trac-validation-provider
                    name="category"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <trac-dropdown
                      :menuItems="categories"
                      type="selectBox"
                      placeholder="Select Category"
                      :title="'Products Category'"
                      v-model="product.category"
                      newModule="Create New Category"
                      allowNewModule
                      :newModuleOnTheFly="createCategoryOnTheFly"
                      class="w-full mt-6"
                    ></trac-dropdown>
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>
                </div>
                <div class="flex-column w-full mt-10 md:mr-24">
                  <trac-textarea
                    v-model="product.description"
                    placeholder="Description"
                  ></trac-textarea>
                </div>
                <div class="mt-8 font-semibold text-sm">
                  <trac-toggle
                    @newval="updateVariationValue"
                    :active="product.variation"
                    v-model="product.variation"
                    >Does this product have variations
                  </trac-toggle>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-5 bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow"
          >
            <trac-custom-header>
              <template slot="leading">Product </template>
              <template slot="trailing">Detail</template>
            </trac-custom-header>
            <div
              v-if="product.variation"
              class="mb-8 border rounded-lg overflow-scroll"
            >
              <table class="md:w-full table-fixed">
                <thead class="bg-blue-100">
                  <tr class="h-12">
                    <th class="text-left text-sm font-semibold uppercase px-4 whitespace-no-wrap">
                      Variation
                    </th>
                    <th class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap">
                      SKU
                    </th>
                    <th class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap">
                      Cost Price
                    </th>
                    <th class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap">
                      Price
                    </th>
                    <th class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap">
                      Stock Available
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="h-12"
                    v-for="(variant, i) in product.varieties"
                    :key="i"
                  >
                    <td class="text-xs font-bold capitalize px-4 whitespace-no-wrap">
                      {{ variant.title }}
                    </td>
                    <td class="text-xs font-bold capitalize px-4 whitespace-no-wrap">
                      {{ variant.barcode }}
                    </td>
                    <td class="text-xs font-bold capitalize px-4 whitespace-no-wrap">
                      {{ variant.cost_price | formatPrice }}
                    </td>
                    <td class="text-xs font-bold capitalize px-4 whitespace-no-wrap">
                      {{ variant.price | formatPrice }}
                    </td>
                    <td class="text-xs font-bold capitalize px-4 whitespace-no-wrap">
                      {{ variant.stock }}
                    </td>
                    <td>
                      <div class="flex justify-end pr-4">
                        <button
                          @click="editVariant(variant)"
                          type="button"
                          class="bg-none border-none text-xs text-primaryBlue"
                        >
                          Edit
                        </button>
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-end pr-4">
                        <img
                          class="cursor-pointer"
                          src="../../assets/svg/close_btn.svg"
                          alt=""
                          @click="removeVariant(variant)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="m-5">
                <trac-button
                  @button-clicked="openVariationModal"
                  variant="outline"
                  >Add Variation</trac-button
                >
              </div>
            </div>

            <trac-modal
              v-if="variationModal"
              class="max-w-2xl w-full"
              @close="variationModal = false"
            >
              <AddVariation
                @addVariant="variantAdded($event)"
                @close="variationModal = false"
                :stores="stores"
                :allCreatedproducts="allCreatedproducts"
                :variantToEdit="variantToEdit"
              ></AddVariation>
            </trac-modal>

            <div class="flex flex-col" v-if="!product.variation">
              <div class="grid md:grid-cols-3 md:w-10/12 relative gap-4">
              <trac-validation-provider
                  name="cost price"
                  rules=""
                  v-slot="{ errors }"
                >
                <trac-input
                  :disabled="product.variation"
                  placeholder="Cost Price"
                  v-model.number="product.cost_price"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                  </trac-validation-provider>
                  
                <trac-validation-provider
                  name="price"
                  rules="required|greaterthanzero"
                  v-slot="{ errors }"
                >
                  <trac-input
                    :disabled="product.variation"
                    placeholder="Price"
                    v-model.number="product.price"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
                <trac-input
                  :disabled="product.variation"
                  v-model="product.barcode"
                  placeholder="Barcode"
                ></trac-input>
                <trac-button
                  class="uppercase mt-3"
                  variant="outline"
                  @button-clicked="generateBarcode"
                  :disabled="product.variation"
                  >Generate Barcode / SKU
                </trac-button>
                <trac-button
                  class="uppercase mt-3"
                  variant="outline"
                  :disabled="product.variation"
                  @button-clicked="showBarcode = true"
                  >Scan Barcode
                </trac-button>
                <div class="absolute right-0 mt-20 h-64 w-64" v-if="showBarcode">
                  <button class="text-xs cursor-pointer text-primaryBlue p-2" @click="showBarcode = false">Close scanner</button>
                  <StreamBarcodeReader
                    @decode="onDecode"
                  ></StreamBarcodeReader>
                </div>
              </div>
            </div>
            <h2 class="pt-8 pb-6 text-primaryBlue">Loyalty</h2>
            <div class="grid gap-2 md:flex items-center md:w-10/12">
              <div class="w-full flex">
                <trac-radio
                  value="default"
                  name="loyalty-data"
                  :selectedVal="loyalty"
                  @change="loyalty = $event"
                  ><p class="text-xs">
                    Earn default loyalty points
                  </p></trac-radio
                >
                <div></div>
              </div>
              <div class="w-full flex items-center">
                <trac-radio
                  value="custom"
                  name="loyalty-data"
                  :selectedVal="loyalty"
                  @change="loyalty = $event"
                  ><p class="text-xs">Create custom loyalty</p></trac-radio
                >
              </div>
            </div>
            <div class="grid md:flex items-center md:w-10/12">
              <div class="w-full flex mt-4">
                <svg
                  class="w-4 h-4"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0003 1.66663C5.39783 1.66663 1.66699 5.39746 1.66699 9.99996C1.66699 14.6025 5.39783 18.3333 10.0003 18.3333C14.6028 18.3333 18.3337 14.6025 18.3337 9.99996C18.3337 5.39746 14.6028 1.66663 10.0003 1.66663ZM10.8337 14.1666H9.16699V9.16663H10.8337V14.1666ZM10.8337 7.49996H9.16699V5.83329H10.8337V7.49996Z"
                    fill="#979797"
                  />
                </svg>
                <div class="text-xs md:pr-20 ml-4 text-primaryGray">
                  This means that for every NGN 100 purchase your customer
                  <br />
                  makes, they get {{ product.loyalty_points | formatPrice }} in
                  loyalty points.
                </div>
              </div>
              <div v-if="loyalty == 'custom'" class="w-full">
                <div class="w-64 mt-6 relative flex items-center">
                  <trac-input
                    v-model.number="product.loyalty_points"
                    class="w-full"
                    placeholder="Set Custom Percentage"
                  ></trac-input>
                  <div class="absolute text-primaryGray right-0 mr-5">%</div>
                </div>
              </div>
            </div>
            <trac-modal
              v-if="comboModal"
              class="max-w-2xl w-full"
              @close="comboModal = false"
            >
              <AddCombo
                :products="sortedProducts"
                @add-combo="comboAdded($event)"
              ></AddCombo>
            </trac-modal>

            <div class="mt-6 w-8/12" v-if="product.combo">
              <div
                v-for="(combo, i) in product.combo_items"
                :key="i"
                class="mt-3 flex flex-row gap-4 items-center"
              >
                <div
                  class="flex flex-row bg-accentLight px-6 w-11/12 justify-between py-3 font-semibold text-xs"
                >
                  <span>{{ combo.title }}</span>
                  <span class="">{{ combo.quantity || combo.stock }}</span>
                </div>
                <div>
                  <img
                    class="cursor-pointer"
                    src="../../assets/svg/close_btn.svg"
                    alt=""
                    @click="removeCombo(combo)"
                  />
                </div>
              </div>
              <div class="mt-5">
                <trac-button @button-clicked="openComboModal" variant="small"
                  >Add another item</trac-button
                >
              </div>
            </div>
            <div class="mt-8 md:mr-32 pt-8 border-t font-semibold text-sm">
                <!-- :disabled="updateProductFlag" -->
              <trac-toggle
                v-model="product.manage_stock"
                :active="product.manage_stock"
                @newval="updateManageStock"
                >Would you like to manage stock for this item</trac-toggle
              >
            </div>
            <div class="mt-10" v-if="product.manage_stock">
              <div v-for="(store, i) in stores" :key="i">
                <span class="font-bold text-lg capitalize">{{
                  store.name
                }}</span>
                <div class="grid md:flex flex-row md:w-11/12 mt-4 md:mt-8 md:mb-12">
                  <div class="flex-column md:w-4/12 md:mr-12">
                    <div class="" v-if="product.variation">
                      <label
                        class=""
                        v-for="(variant, i) in product.varieties"
                        :key="i"
                      >
                        <span class="capitalize mb-3 block text-gray-600">{{
                          variant.title
                        }}</span>
                        <trac-input
                          :disabled="updateProductFlag"
                          v-model.trim.number="
                            (specificProductStoreStock(store, variant) || {}).stock
                          "
                          placeholder="Stock Level"
                        ></trac-input>
                      </label>
                    </div>
                    <trac-input
                      :disabled="updateProductFlag"
                      v-else
                      v-model.trim.number="
                        (specificProductStoreStock(store) || {}).stock
                      "
                      placeholder="Stock Level"
                    ></trac-input>
                  </div>
                  <div class="flex-column md:w-4/12 md:mr-12 my-6 md:my-0">
                    <div class="" v-if="product.variation">
                      <label
                        class=""
                        v-for="(variant, i) in product.varieties"
                        :key="i"
                      >
                        <span class="opacity-0 mb-3 block text-gray-600">{{
                          variant.title
                        }}</span>
                        <trac-input
                          :disabled="updateProductFlag"
                          v-model.trim.number="
                            (specificProductStoreStock(store, variant) || {}).re_order
                          "
                          placeholder="Reorder Level"
                        ></trac-input>
                      </label>
                    </div>
                    <trac-input
                      :disabled="updateProductFlag"
                      v-else
                      v-model.trim.number="
                        (specificProductStoreStock(store) || {}).re_order
                      "
                      placeholder="Reorder Level"
                    ></trac-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-8 md:justify-end">
            <div class="flex-column md:w-12/12">
              <trac-button class="uppercase" buttonType="submit"
                >{{
                  updateProductFlag ? "Update" : "Create"
                }}
                Product</trac-button
              >
            </div>
          </div>
        </form>
      </trac-validation-observer>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import AddVariation from "./AddVariation";
import AddCombo from "./AddCombo";
import { GENERATE_RANDOM_ID } from "../../browser-db-config/utilityMethods";
import { eventBus } from "./../../main";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { permissionsModules } from "./../settings/permission";

export default {
  name: "NewProduct",
  components: { AddVariation, AddCombo, StreamBarcodeReader },
  data() {
    return {
      isLoading: false,
      updateProductFlag: false,
      fromCategoryModule: false,
      dataLoaded: false,
      showBarcode: false,
      permissionsModules,
      loyalty: "default",
      product: {
        title: "",
        barcode: "",
        category: "",
        description: "",
        image: "",
        price: "",
        cost_price: "",
        stock: 0,
        varieties: [],
        variation: false,
        manage_stock: false,
        combo: false,
        combo_items: [],
      },
      imageToUpload: {
        src: null,
        file: null,
      },
      stores: null,
      loyaltyData: null,
      variantToEdit: null,
      allCreatedproducts: null,
      variationModal: false,
      comboModal: false,
      categories: [],
    };
  },
  computed: {
    sortedProducts() {
      return this.allCreatedproducts.sort((a, b) =>
        a.title > b.title ? 1 : -1
      );
    },
  },
  async created() {
    this.loyaltyData = GET_USER_BUSINESS_DATA().loyalty_active;

    if (this.loyaltyData) {
      this.loyalty = "default";
    }

    // clear on the fly module
    DELETE_LOCAL_DB_DATA("create-category-on-the-fly");

    this.isLoading = true;
    this.updateProductFlag = GET_LOCAL_DB_DATA("product-details")
      ? GET_LOCAL_DB_DATA("product-details")._id
        ? true
        : false
      : false;

    if (this.updateProductFlag || GET_LOCAL_DB_DATA("product-details")) {
      this.product = GET_LOCAL_DB_DATA("product-details");
    }

    await this.fetchAllStores();
    await this.fetchAllProducts();
    await this.fetchAllCategories();

    if (!this.updateProductFlag) {
      this.product = {
        ...this.product,
        store_stock: this.stores.map((store) => {
          return {
            re_order: 0,
            stock: 0,
            store_id: store._id,
            store_name: store.name,
          };
        }),
      };
    }

    // Set category if any
    this.fromCategoryModule = GET_LOCAL_DB_DATA("category-detail");
    if (this.fromCategoryModule) {
      this.product.category = this.fromCategoryModule
        ? this.fromCategoryModule.name
        : "";
    }
    this.dataLoaded = true;
    this.isLoading = false;
  },

  methods: {
    onDecode(x) {
      if (x) {
        this.product.barcode = x;
        this.showBarcode = false;
      }
    },
    createCategoryOnTheFly() {
      if (
        permissionsModules[4]["parent"]["permissionsForUser"]["createCategory"]
      ) {
        const payload = {
          origin: {
            // name: "Add Invoice",
          },
          product: this.product,
        };

        SAVE_LOCAL_DB_DATA("create-category-on-the-fly", payload);
        this.$router.push({ name: "AddCategories" });
      } else {
        eventBus.$emit("trac-alert", {
          message: "You have the permission to create a product.",
        });
      }
    },
    sendForm() {
      if (this.updateProductFlag) {
        this.updateProduct();
      } else {
        this.createProduct();
      }
    },
    updateVariationValue(value) {
      this.product.variation = !value;
    },
    updateManageStock(value) {
      this.product.manage_stock = !value;
    },
    updateComboValue(value) {
      this.product.combo = !value;
    },
    openVariationModal() {
      this.variationModal = true;
    },
    openComboModal() {
      this.comboModal = true;
    },
    editVariant(variant) {
      this.variantToEdit = variant;
      this.variationModal = true;
    },
    variantAdded(variant) {
      if (!this.variantToEdit) {
        this.product.varieties.push({
          ...variant,
          clientID: GENERATE_RANDOM_ID(),
        });
      }
      this.variationModal = false;
    },
    removeVariant(variantProduct) {
      this.product.varieties = this.product.varieties.filter(
        (variant) => variantProduct.clientID !== variant.clientID
      );
    },
    comboAdded(comboProduct) {
      this.product.combo_items.push(comboProduct);
      this.comboModal = false;
    },
    removeCombo(comboProduct) {
      this.product.combo_items = this.product.combo_items.filter(
        (combo) => comboProduct.title !== combo.title
      );
    },
    async uploadProductImage() {
      this.isLoading = true;
      await this.$store.dispatch("UPLOAD_FILE", this.imageToUpload);
      const res = this.$store.getters["GET_UPLOAD_RES"];

      this.$store.commit("SETUP_FILE_UPLOADED", null);

      if (res.status) {
        this.product.image = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async createProduct() {
      this.isLoading = true;
      if (this.imageToUpload.file) {
        await this.uploadProductImage();
      }

      await this.$store.dispatch("CREATE_PRODUCT", this.product);
      const res = this.$store.getters["GET_CREATED_PRODUCT_RES"];

      this.$store.commit("SETUP_CREATED_PRODUCT", null);

      if (res.status) {
        this.$router.back();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async updateProduct() {
      this.isLoading = true;
      if (this.imageToUpload.file) {
        await this.uploadProductImage();
      }

      await this.$store.dispatch("UPDATE_PRODUCT", this.product);
      const res = this.$store.getters["GET_CREATED_PRODUCT_RES"];

      this.$store.commit("SETUP_CREATED_PRODUCT", null);

      if (res.status) {
        this.$router.back();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    triggerFileOpen() {
      const fileInput = document.querySelector("#product-image");
      fileInput.click();
    },
    selectImage(e) {
      this.imageToUpload.file = e.target.files[0];
      this.imageToUpload.src = window.URL.createObjectURL(
        this.imageToUpload.file
      );
    },
    removeImage() {
      this.imageToUpload = {
        src: null,
        file: null,
      };
      this.product.image = "";
    },
    specificProductStoreStock(store, variant) {
      let specificStore = null;
      if (this.product.variation) {
        this.product.varieties.forEach((prodVariant) => {
          prodVariant.store_stock.forEach((ss) => {
            if (
              ss.store_id === store._id &&
              prodVariant.barcode === variant.barcode
            ) {
              specificStore = ss;
            }
          });
        });
      } else {
        specificStore = (this.product.store_stock || []).find((ss, index) => {
          // return ss.store_id === store._id || ss.store_id === store._id;
          return ss.store_id === store._id;
        });
      }

      if (specificStore) {
        return specificStore;
      } else {
        // alert("Error: Store stock unassigned.");
        // eventBus.$emit("trac-alert", {
        //   message: "Error: Store stock unassigned.",
        // });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
    },
    async fetchAllCategories() {
      await this.$store.dispatch("FETCH_ALL_CATEGORIES");
      const res = this.$store.getters["GET_ALL_CATEGORIES"];

      if (res.status) {
        const data = res.data || [{ name: "", count: 0 }];
        if (data.length > 1) {
          this.categories = data.slice(1, data.length);
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        this.allCreatedproducts = res.items || [];
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
    async generateBarcode() {
      this.isLoading = true;
      await this.$store.dispatch("GENERATE_BARCODE");
      const res = this.$store.getters["GET_GENERATED_BARCODE"];

      this.$store.commit("POPULATE_GENERATED_BARCODE", null);

      if (res.status) {
        this.product.barcode = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.no-img-bg {
  background-color: #e2e2e2;
}
</style>
