<template>
  <main class="">
    <h1 class="font-bold mb-6 pt-1">Products</h1>
    <div class="overflow-scroll list-hold pr-5">
      <div
        class="flex justify-between p-3 cursor-pointer hover:bg-gray-200"
        v-for="(product, i) in products"
        :key="i"
        @click="$emit('add-combo', product)"
      >
        <div class="">
          <p class="text-xs capitalize">{{ product.title }}</p>
          <span class="text-xs text-gray-500">{{ product.stock }} unit(s)</span>
        </div>
        <p class="text-xs">{{ product.price | formatPrice }}</p>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.list-hold {
  max-height: 24rem;
}
</style>
